import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import GrtSecurityLayout from '../../layouts/security/security.layout.vue'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'

@Component({
  name: 'GtrLoginView',
  computed: {
    ...mapState('security', ['twofactorSent'])
  }
})
export default class GtrLoginView extends Vue {
  @Ref()
  readonly observerLoginForm!: InstanceType<typeof ValidationObserver>

  data () {
    return {
      credentials: {
        email: null,
        password: null,
        two_factor_token: null,
        ttl: 60
      },
      showPassword: false,
      submitting: false,
      two_factor_prompt: false,
      two_factor_user_step_one: false
    }
  }

  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  @Watch('twofactorSent', { immediate: true })
  onVisibleChange (newValue: boolean) {
    this.$data.two_factor_prompt = newValue
  }

  async submit () {
    const observerLoginForm = (this.$refs.observerLoginForm as any)
    const isValid = await observerLoginForm.validate()
    let isError = false

    if (isValid) {
      try {
        this.$data.submitting = true
        const response = await this.$store.dispatch(
          'security/login',
          this.$data.credentials
        )
        if (response.authorization_type !== '2fa') {
          const user = response.user
          if (user) {
            this.redirectToByUserLevel(user)
            this.handleHideTwoFactorPrompt()
          } else {
            this.$router.push({ name: 'security.login' })
          }
        } else {
          Container.get(Notification).success('Two factor code sent successfully')
          this.$data.two_factor_user_step_one = true
        }
      } catch (error) {
        if (error?.data?.error_message === 'WRONG_TOKEN') {
          Container.get(Notification).error('Code is not valid')
        } else {
          Container.get(ErrorHandlerService).error(error)
        }
        isError = true
      } finally {
        if (!this.$data.two_factor_user_step_one && !isError) {
          this.observerLoginForm.reset()
          this.$data.credentials = {
            email: null,
            password: null,
            two_factor_token: null
          }
        }
        this.$data.two_factor_user_step_one = false
        this.$data.submitting = false
      }
    }
  }

  private redirectToByUserLevel (user: any) {
    const accessLevel = user.access_level
    this.$bus.$emit('gtr-plan-change')
    const originalUrl = Container.get(GtrStorage).getItem('originalUrl')
    const sessionStorageLastUrl = sessionStorage.getItem('last_url')
    if (originalUrl) {
      Container.get(GtrStorage).removeItem('originalUrl')
      Container.get(GtrStorage).setItem('just_logged_in', 'true')
      location.href = originalUrl
    } else if (sessionStorageLastUrl) {
      location.href = sessionStorageLastUrl
    } else {
      if (accessLevel === AccessLevel.SUPER_ADMIN || accessLevel === AccessLevel.RESELLER_ADMIN) {
        this.$router.push({
          name: 'level-one.dashboard'
        }, () => {
          this.$bus.$emit('gtr-load-companies')
        })
      } else {
        if (user.company === null) {
          this.$router.push({ name: 'level-two.company.register' })
        } else {
          this.$router.push({
            name: 'level-two.company.dashboard',
            params: { uuid: user.company.uuid }
          })
        }
      }
      this.$bus.$emit('login')
    }
  }

  async resendTwoFactorCode () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('security/get2faCode', {
        data: {
          email: this.$data.credentials.email,
          password: this.$data.credentials.password
        }
      })
      Container.get(Notification).success('Two factor code sent successfully')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleHideTwoFactorPrompt () {
    if (this.$data.two_factor_prompt) {
      this.$data.two_factor_prompt = false
      this.$store.state.security.twofactorSent = false
    }
  }
}
